<template>
    <b-dropdown size="sm" variant="link" toggle-class="topbar-item text-decoration-none" no-caret right no-flip>
        <template v-slot:button-content>
            <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-warning">
                  <span class="svg-icon svg-icon-xl svg-icon-primary">
                    <b-badge v-if="notifications_count > 0" pill variant="danger">{{notifications_count}}</b-badge>
                    <b-icon-bell-fill variant="warning"></b-icon-bell-fill>
                  </span>
                <span v-if="notifications_count > 0" class="pulse-ring"></span>
            </div>
        </template>
        <b-dropdown-text tag="div" style="width: 350px;">
            <div>
                <div class="d-flex flex-column pt-5 bgi-size-cover bgi-no-repeat rounded-top" style="background-color: #FFB803 !important;">
                    <h4 class="d-flex justify-content-between rounded-top pl-5 pr-5">
                        <span class="text-white">{{$t('notifications.user_notifications')}}</span>

                        <router-link to="/notifications" v-slot="{ href, navigate, isActive, isExactActive }">
                            <a :href="href" class="btn btn-text btn-primary btn-sm font-weight-bold btn-font-md ml-2" @click="navigate"> {{ $t('MENU.all_notifications') }}</a>
                        </router-link>
                    </h4>
                </div>
                <div v-if="list.length > 0" class="d-flex flex-column pt-4">
                    <div class="d-flex justify-content-end rounded-top pl-5 pr-5">
                            <a href="javascript:;" class="font-weight-bold ml-2" @click="setNotificationsAllRead"> {{ $t('make_all_read') }}</a>
                    </div>
                </div>
                <div>
                    <perfect-scrollbar v-if="list.length > 0" class="navi navi-hover scroll my-4" style="max-height: 40vh; position: relative;">
                        <template v-for="(item, i) in list">
                            <a class="navi-item" v-bind:key="i">
                                <div :class="{'navi-link':true, 'bg-f6f7fb': item.is_read == 0}">
                                    <div class="navi-icon mr-2">
                                        <i v-bind:class="'flaticon2-notification text-0C6F58'"></i>
                                    </div>
                                    <div class="navi-text">
                                        <div class="font-weight-bold text-h-3">
                                            {{ item.notes }}
                                        </div>
                                        <div class="text-muted">
                                            {{ item.time }}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </template>
                    </perfect-scrollbar>
                    <div v-if="list.length == 0" class="d-flex flex-center text-center text-muted min-h-200px">
                        {{$t('notifications.no_new_notifications')}}.
                    </div>
                </div>
            </div>
        </b-dropdown-text>
    </b-dropdown>
</template>

<style lang="scss">
    /* hide default vue-bootstrap tab links */
    .hide-tabs > div:not(.tab-content) {
        display: none;
    }
</style>

<script>
    import ApiService from "@/core/services/api.service";
    import {onMessage, getToken} from "firebase/messaging";
    import {mapGetters} from "vuex";
    import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_COUNT } from "@/core/services/store/notification.module";

    export default {
        name: "KTDropdownNotification",
        data() {
            return {
                mainRoute: '/notifications',
                // notifications_count: 0,
                // list: [],
                notifi_token: null,
            };
        },
        methods: {
            notificationsData(){
                this.$store.dispatch(GET_NOTIFICATIONS);
                this.$store.dispatch(GET_NOTIFICATIONS_COUNT);
            },
            setNotificationsAllRead() {
                ApiService.post(this.mainRoute + "/make_all_read").then(() => {
                    this.notificationsData();
                });
            },
            setTokenForUser() {
                ApiService.post(this.mainRoute + "/set_user_notification_token",{
                    token: this.notifi_token,
                });
            },

        },
        computed: {
            ...mapGetters({  list:"getList",  notifications_count: "getCount"}),
        },
        mounted() {
            let that = this;
            this.notificationsData();
            let _lang = localStorage.getItem("language") || 'ar';
            /**
             * listen to handling message
             */
            setTimeout(() => {
                let soundUrl = '/media/sounds/audio.mp3' 

                onMessage(window.firebase_messaging, (payload) => {

                    let _data = payload.data;
                    this.$bvToast.toast(_data.body, {
                        title: this.$t('notifications.user_notifications'),
                        variant: 'info',
                        solid: true,
                        toaster: 'b-toaster-bottom-'+(_lang == "ar"?'left':'right'),
                    });
                    let audio = new Audio(soundUrl);
                    audio.play();
                    that.notificationsData();
                });
            }, 3000);

            Notification.requestPermission().then(function()
            {
                /**
                 * get new token from firebase
                 */
                getToken(window.firebase_messaging).then((currentToken) => {
                    if (currentToken) {
                        that.notifi_token = currentToken;
                        that.setTokenForUser();
                        // console.log(currentToken);
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
                // if (permission === "granted") {
                //     //redirect using javascript here window.location
                //     location.reload();
                // }
            });


        }
    };
</script>
